import React from 'react';
import { Container, Grid, Typography,  List, ListItem, ListItemText, makeStyles  } from '@mui/material';
import CustomCard from '../components/Card';
import styles from '../styles/homeStyles.module.css';
import DisclosureBox from './Disclosures';



const HomePage: React.FC = () => {

    // Detect the user's device and operating system
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  
    // Initialize the app store URL
    let appStoreUrl = '';
  
    // Set the URL based on the detected device and OS
    if (isAndroid) {
      // URL for the Android app on the Google Play Store
      appStoreUrl = 'https://play.google.com/store/apps/details?id=com.gamestop.powerup&pli=1';
    } else{
      // URL for the iOS app on the Apple App Store
      appStoreUrl = 'https://apps.apple.com/us/app/gamestop/id406033647';
    }

  let cards = [
  //   { id: 1, image: './images/expresscardbg.png', title: 'Apply Now', url: 'https://acquire1uat.comenity.net/?clientName=express&kioskMode=true&mockIC=successCb' },
  //  { id: 2, image: './images/expressinsiderbg.png', title: 'Express Insider', url: 'https://www.express.com/g/insider/program-benefits' },
  //  { id: 3, image: './images/downloadapp.png', title: 'Download App', url: appStoreUrl },
  // { id: 1, herotitle: 'APPLY NOW', title: 'APPLY NOW', url: 'https://acquire1.comenity.net/?clientName=gamestop' },
  // { id: 8, herotitle: 'REDEEM REWARDS', title: 'JOIN FOR FREE', url: 'https://www.gamestop.com/' },
  // { id: 9, herotitle: 'SIGNUP', title: 'DOWNLOAD NOW', url: 'https://www.gamestop.com/' },
  { id: 6, image: './images/apply.png', title: 'APPLY', url: 'https://acquire1.comenity.net/?clientName=gamestop&workflow=phoneLookup' },
  { id: 7, image: './images/redeemRewards.png', title: 'REDEEM REWARDS', url: 'https://www.gamestop.com/' },
  { id: 8, image: './images/usersignup.png', title: 'SIGNUP', url: 'https://aspire-sdk-demos.myshopify.com/pages/finance' },
  ];
 
  let homelogo = './images/gamestoplogo.svg';



  return (
  

    <Container>
    <div className={styles.heroimagediv}>
          <img src={homelogo}className={styles.pageLogo} alt="My Image" />

          {/* <Typography className={styles.PageTitle}>Free Year of Pro when card is activated (as part of first transaction)
Double your points! Cardholders earn up to 4% Back in Rewards.<br></br>
20 points per $1 spent on the card, on top of the 20 points per $1 spent for being a Pro = 40 points per $1 total<br></br>
Points don’t expire for active cardholders<br></br>
Includes all their points, not just points earned on the card</Typography> */}

    </div>

    <div className={styles.PageTitle}>
    <ul>
        <li className={styles.boldListItem}>Free Year of Pro when card is activated (as part of first transaction)</li>
        <li className={styles.boldListItem}>Double your points! Cardholders earn up to 4% Back in Rewards.
          <ul className={styles.subList}>
            <li>20 points per $1 spent on the card, on top of the 20 points per $1 spent for being a Pro = 40 points per $1 total</li>
          </ul>
        </li>
        <li className={styles.boldListItem}>Points don’t expire for active cardholders
          <ul className={styles.subList}>
            <li>Includes all their points, not just points earned on the card.</li>
          </ul>
        </li>
      </ul>
    </div>

    {/* Card grid */}
    <Grid container spacing={3}>
      {cards.map((card) => (
        <Grid item key={card.id} xs={12} sm={6} md={4} lg={3}>
             <CustomCard image={card.image} title={card.title} url={card.url}  />
             {/* <CustomCard herotitle={card.herotitle} title={card.title} url={card.url}  /> */}

        </Grid>
      ))}
    </Grid>
    <DisclosureBox/>
    
  </Container>
  );
};

export default HomePage;
