import React from 'react';
import logoImage from './express.svg'; // Import the logo image
import styles from '../styles/homeStyles.module.css'; // Import any CSS styles for the logo

const HomeLogo = () => {
  return (
    <div>
      <img src={logoImage} className={styles.pageLogo} alt="My Image" />
      {/* Add any additional text or styling for the logo */}
    </div>
  );
};

export default HomeLogo;
