import React from 'react';
import { Box, Typography } from '@mui/material';


const DisclosureBox = () => {
  const textWithSuperscript = "*Application and approval must occur same day to receive the offer. Cannot be combined with other credit offers. Valid one time only. The entire transaction amount after discount must be placed on the GAMESTOP Credit Card.\
  \n\n\
  Points\
  \n\n\
  <sup>1</sup> 2X points equals 20 points per $1 spent. Offer is available to all GAMESTOP members. Excludes purchases covered by the redemption of GAMESTOP Cash or program coupon, GAMESTOP Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery. This rewards program is provided by GAMESTOP and its terms may change at any time. For full Rewards Terms and Conditions, please see http://GAMESTOP.com/insiderrules.\
  \n\n\
  <sup>2</sup> 2.5X points equals 25 points per $1 spent. Offer is available to all GAMESTOP members. Excludes purchases covered by the redemption of GAMESTOP Cash or program coupon, GAMESTOP Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery.\
  \n\n\
  GAMESTOP Cash\
  \n\n\
  <sup>3</sup> Offer is available to GAMESTOP members. Click here for terms and conditions.\
  \n\n\
  Birthday Gifts\
  \n\n\
  <sup>4</sup> Offer is available to GAMESTOP members. Click here for terms and conditions.\
  \n\n\
  Free Shipping\
  \n\n\
  <sup>5</sup> Limited to U.S. Standard Shipping. Not valid on previous purchases. No promo code required to redeem free shipping, just select Standard Shipping during checkout. If an expedited shipping method is chosen, Second Day Delivery or Overnight Delivery, the stated shipping and handling fees will apply. If you return a portion of your online purchase, shipping charges will be assessed for the return shipping costs. Offer is exclusive to GAMESTOP Credit Card holders enrolled in the GAMESTOP program.\
  \n\n\
  \
  \n\n\
  <sup>6</sup>Spend $500 (or earn 10,000+ points through a combination of spend and bonus offers) within the program year earning period (defined as March 1 to the last day of February the following year) on qualifying purchases made with your GAMESTOP Credit Card, minus returns, to upgrade to or maintain  status. Spend amount excludes GAMESTOP Marketplace purchases and Style Trial transactions.\
  \n\n\
  *Earning will be based on your rewards tier. Excludes purchases covered by the redemption of GAMESTOP Cash or program coupon, GAMESTOP Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery. This rewards program is provided by GAMESTOP and its terms may change at any time. For full Rewards Terms and Conditions, please see http://GAMESTOP.com/insiderrules. Influencer tier reward members earn 8% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.  tier reward members earn 10% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.\
  \n\n\
  \n\n\
  Credit card offers are subject to credit approval.\
  \n\n\
  GAMESTOP Credit Card Accounts are issued by Comenity Bank.";
 
  return (
    <Box
      sx={{
        backgroundColor: '#EAEAEA',
        padding: '1rem',
        marginTop: '3.5rem'
      }}
    >
      {/* <Typography variant="body1" sx={{fontSize: '0.8em',color: '#0000008a'}}>*Valid one time only. Redemtion Details: Offer will not be re-issued if lost, stolen or destroyed or with return/refund of merchandise.
<br></br>
<br></br>
+Based on  25 points per $1. Offers valid for GAMESTOP Credit Card purchases only. Earning based on your rewards tier. Excludes purchases covered by the redemption of GAMESTOP Cash or program coupon, GAMESTOP Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery. Influencer tier reward members earn 8% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.  tier reward members earn 10% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.
<br></br>
<br></br>
Credit card offers are subject to credit approval. 
<br></br>
GAMESTOP Credit Card Accounts are issued by Comenity Bank.</Typography> */}

<Typography variant="body1" sx={{fontSize: '0.7em',color: '#0000008a', whiteSpace: 'pre-line'}} >

<sup>*</sup>Get $50 Extra Trade Credit when trading in any PS4, PS5, Xbox Series S or X, Xbox One, or Nintendo Switch towards a new PS5 Console: Applies to Trade Credit Only, Excludes Cash Trades. *Pros Get 10% Extra Trade Credit: Applies to trade credit only.

 

<br></br><br></br>

<sup>*</sup>Get 20% Extra Trade Credit when trading towards Assassin's Creed Mirage, Super Mario Wonder, Spider-Man 2, or Call of Duty Modern Warfare III: Applies to trade credit only, excludes cash trades. *Pro 10% Extra Trade Credit Bonus: Excludes tech trades. Extra Trade Credit is in the form of cash or GameStop store credit only.

 
<br></br><br></br>
<sup>*</sup>Get Asgard's Wrath 2 free with purchase, plus an exclusive Asgard’s Wrath 2 lens cloth: Meta Quest is for ages 13+. Asgard's Wrath 2: For ages 17+, limited time only, offer ends 1/27/24, terms apply. Asgard's Wrath 2 lens cloth: Available while supplies last.
<br></br><br></br>
<sup>*</sup>Pros Get 5% Extra Off:
 
<br></br><br></br>
Includes ALL Pre-Owned: Pre-Owned Consoles, Pre-Owned Video Games, Pre-Owned Video Game Accessories, & Pre-Owned Tech. All Collectibles, Trading Cards, Toys, & Apparel: Category also includes: board games, blind bags, action figures, replicas, & statues. GameStop Brand Products: Includes products from Atrix, Geeknet, & Modern Icon. Excludes GameStop gift cards. All GameStop Product Protection Plans: Applies only to GameStop product replacement plans and Game Play Guarantee plans purchased from GameStop in-store. A product protection plan is a service contract. See service contract terms for specific coverage, limitations, and exclusions.
<br></br>
<sup>4</sup>Offer is available to GAMESTOP members. Click here for terms and conditions.

<br></br><br></br>
Free Shipping
<br></br>
<sup>5</sup>Limited to U.S. Standard Shipping. Not valid on previous purchases. No promo code required to redeem free shipping, just select Standard Shipping during checkout. If an expedited shipping method is chosen, Second Day Delivery or Overnight Delivery, the stated shipping and handling fees will apply. If you return a portion of your online purchase, shipping charges will be assessed for the return shipping costs. Offer is exclusive to GAMESTOP Credit Card holders enrolled in the GAMESTOP program.
 
<br></br><br></br>

<br></br>
<sup>6</sup>Spend $500 (or earn 10,000+ points through a combination of spend and bonus offers) within the program year earning period (defined as March 1 to the last day of February the following year) on qualifying purchases made with your GAMESTOP Credit Card, minus returns, to upgrade to or maintain  status. Spend amount excludes GAMESTOP Marketplace purchases and Style Trial transactions.

<br></br><br></br>
<sup>*</sup>Earning will be based on your rewards tier. Excludes purchases covered by the redemption of GAMESTOP Cash or program coupon, GAMESTOP Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery. This rewards program is provided by GAMESTOP and its terms may change at any time. For full Rewards Terms and Conditions, please see http://GAMESTOP.com/insiderrules. Influencer tier reward members earn 8% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.  tier reward members earn 10% back in GAMESTOP Cash when they use their GAMESTOP Credit Card.
 

 
<br></br><br></br>
Credit card offers are subject to credit approval.
<br></br>
GAMESTOP Credit Card Accounts are issued by Comenity Bank.
  </Typography>
    </Box>
  );
};

export default DisclosureBox;
